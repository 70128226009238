// Start with global styles
import "@css/var.css"; // Global
/* BEGIN: Set domain-specific values */
// Subdomain override styles
import "@css/app-swingu/var.css";
// @TODO - deprecate and phase out SCSS
import "@scss/app-swingu/app.scss";
// Path to subdomain layout file
import Layout from "@components/app-swingu/layout.vue";
// `js/components/${vueDir}/
let vueDir = "app-swingu";
// Accessible in Vue via `this.$constants
const subdomainConstants = {
    test: {
        nestedItem: 1,
    },
};
/* END: Set domain-specific values */

/**
 * Prevent XSRF-TOKEN stored on top-level wildcard domain cookie from interfering with
 * current site.
 */
//document.cookie = 'XSRF-TOKEN=; Max-Age=0; path=/; domain=.swingu.com';

/**
 * SETUP NOTE: Subdomain must also be added to vite.config.js
 */

import { init } from "./main";
init(vueDir, Layout, subdomainConstants);
